import dynamic from 'next/dynamic';

import { useNotification } from '@/core/components/notification/notification-state';

const Notification = dynamic(
    () =>
        import(
            /* webpackChunkName: "Notification" */
            '@/core/components/notification/notification'
        ),
);

export default function NotificationWrapper() {
    const [notification] = useNotification();

    if (!notification) {
        return null;
    }

    return <Notification {...notification} />;
}
