import type { MapAccommodationFilterType } from '@/features/accommodation-filter/accommodation-filter-type';

import Divider from '@/core/components/divider/divider';
import { scrollToTop } from '@/core/features/scroll/scroll-service';
import { useAccommodationFilterState } from '@/features/accommodation-filter/accommodation-filter-state';
import { useFilterLoading } from '@/features/filter/filter-data/filter-state';
import { useAccommodationFilterDataMution } from '@/features/filter/filter-data/use-filter-data-mutation';
import MapAccommodationFilterGroupDesktop from '@/features/map/map-components/map-filter/map-accommodation-filter-group-desktop';
import useMapFilterScrollRestoration from '@/features/map/map-components/map-filter/use-map-filter-scroll-restoration';

type MapAccommodationFilterDesktopProps = {
    accommodationFilters: MapAccommodationFilterType;
    componentId: string;
};

export default function MapAccommodationFilterDesktop({
    accommodationFilters,
    componentId,
}: MapAccommodationFilterDesktopProps) {
    const { filterContainerRef, onFilterReset } = useMapFilterScrollRestoration();

    const { hasNonDefaultAccommodationFilterOptionSelected } = useAccommodationFilterState();
    const [isFilterLoading] = useFilterLoading();
    const { resetFilter, toggleFilter } = useAccommodationFilterDataMution();

    const resetFilters = () => {
        resetFilter();
        onFilterReset();
        scrollToTop();
    };

    if (!accommodationFilters?.groups) {
        return null;
    }

    return (
        <div
            className={'height-100 overflow-y-scroll'}
            ref={filterContainerRef}
            /* eslint-disable no-inline-styles/no-inline-styles */
            style={{
                marginLeft: '-5px',
                marginTop: '-5px',
                maxWidth: '272px',
                minWidth: '272px',
            }}
            /* eslint-enable no-inline-styles/no-inline-styles */
        >
            <div
                className={
                    'border-radius-5 bg-white border-none padding-top-0 padding-right-20 padding-left-20 padding-bottom-20'
                }
            >
                <div className={'flex-start-vertical gap-5'}>
                    <div className={'font-size-16 bold padding-top-20 margin-top-0'}>{accommodationFilters?.title}</div>
                    {hasNonDefaultAccommodationFilterOptionSelected && (
                        <div
                            className={'text-left'}
                            onClick={resetFilters}
                        >
                            <button
                                className={
                                    'width-100 font-size-12 pointer relative text-endeavour area-expansion-after'
                                }
                            >
                                alle zurücksetzen
                            </button>
                        </div>
                    )}
                    <Divider
                        className={'width-100'}
                        color={'alto'}
                        marginTop={10}
                    />
                </div>
                {accommodationFilters.groups.map((group, index) => {
                    return (
                        group &&
                        !!group.options.length && (
                            <MapAccommodationFilterGroupDesktop
                                componentId={componentId}
                                filterGroup={group}
                                isLastGroup={index < accommodationFilters.groups.length - 1}
                                isLoading={isFilterLoading}
                                key={`${group.title}-${index}`}
                                toggleFilter={toggleFilter}
                            />
                        )
                    );
                })}
            </div>
        </div>
    );
}
