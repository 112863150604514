import React from 'react';

import debounce from 'lodash.debounce';

import useHasInteractedRef from '@/core/features/scroll/use-has-interacted-ref';

/**
 * restore scroll position when scrollTop value is given
 * and push current scroll position to a callback
 */

export default function useContentScrollRestoration<T extends HTMLDivElement = HTMLDivElement>({
    debounceTime = 100,
    isInitializingRef,
    onScroll,
    ref,
    scrollTop,
}: {
    debounceTime?: number;
    isInitializingRef?: React.MutableRefObject<boolean>;
    onScroll?: (top: number) => void;
    ref?: React.MutableRefObject<T | null>;
    scrollTop: number;
}) {
    const _contentRef = React.useRef<HTMLDivElement | null>(null);
    const contentRef = ref || _contentRef;
    const hasInteractedRef = useHasInteractedRef(contentRef.current);

    const isCancelledRef = React.useRef(false);

    const timeoutRefs = React.useRef<NodeJS.Timeout[]>([]);

    const cancel = () => {
        isCancelledRef.current = true;
    };

    const scrollHandler = React.useCallback(() => {
        if (isCancelledRef.current) {
            return;
        }
        if (hasInteractedRef?.current) {
            return;
        }
        if (!(contentRef.current instanceof HTMLElement)) {
            return;
        }

        contentRef.current.scrollTo({ behavior: 'auto', top: scrollTop });
    }, [hasInteractedRef, contentRef, scrollTop]);

    /**
     * Effect: restore scroll position
     */
    React.useEffect(() => {
        scrollHandler();

        // make sure scroll position is restored also for less performant devices unless user interacts
        timeoutRefs.current = [
            setTimeout(() => {
                scrollHandler();
            }, 100),
            setTimeout(() => {
                scrollHandler();
            }, 1000),
            setTimeout(() => {
                scrollHandler();
            }, 2000),
            setTimeout(() => {
                scrollHandler();
            }, 3000),
        ];

        return () => {
            timeoutRefs.current.forEach((timeout) => clearTimeout(timeout));
            timeoutRefs.current = [];
        };
    }, [scrollHandler, scrollTop]);

    /**
     * Effect: save scroll position on scroll after debounce
     */
    React.useEffect(() => {
        const scrollHandler = debounce(() => {
            if (isInitializingRef?.current) {
                return;
            }

            if (!contentRef.current) {
                return;
            }

            onScroll?.(contentRef.current.scrollTop);
        }, debounceTime);

        if (contentRef.current instanceof HTMLElement) {
            window.addEventListener('scroll', scrollHandler, true);
        }

        return () => {
            window.removeEventListener('scroll', scrollHandler, true);
        };
    }, [onScroll, contentRef, debounceTime, isInitializingRef]);

    return { cancel, contentRef };
}
