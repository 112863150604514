import type { FilterGroupV1Option, FilterGroupV1Type } from '@/features/filter/filter-data-v1-type';

import usePendingState from '@/core/features/render/use-pending-state';
import { isFilterActive, isFilterSelectable } from '@/features/filter/filter-data/service/filter-data-service';

export default function useFilterChip(
    option: FilterGroupV1Option,
    groupType: FilterGroupV1Type,
    showDefaultSelected: boolean,
    isLoading: boolean,
    _onClick: (option: FilterGroupV1Option) => void,
) {
    const { count, isSelected, title } = option;
    const [pendingState, setPendingState] = usePendingState<boolean>(8000, isSelected);

    const isActive = isFilterActive(option, showDefaultSelected, pendingState);

    const onClick = () => {
        const isSelectable = isFilterSelectable(groupType, option, isActive, isLoading);

        if (!isSelectable) {
            return;
        }

        setPendingState(!isActive);
        _onClick(option);
    };
    return { count, isActive, onClick, title };
}
