import type { FilterGroupDesktopType } from '@/features/filter/filter-group-desktop-v1/filter-group-desktop-v1-type';

import React from 'react';

import FilterGroupDesktopV1 from '@/features/filter/filter-group-desktop-v1/filter-group-desktop-v1';
import useMapFilterScrollRestoration from '@/features/map/map-components/map-filter/use-map-filter-scroll-restoration';

import styles from './map-filter-desktop.module.scss';

type MapQuickFilterDesktopProps = {
    componentId: string;
    filterGroups: FilterGroupDesktopType[];
};

export default function MapFilterDesktop({ componentId, filterGroups }: MapQuickFilterDesktopProps) {
    const { filterContainerRef, onFilterReset } = useMapFilterScrollRestoration();

    return (
        <div
            className={styles.mapQuickFilterDesktopContainer}
            ref={filterContainerRef}
        >
            <FilterGroupDesktopV1
                attributes={{ filterGroups }}
                componentId={componentId}
                disableBorders
                marginTop={15}
                onFilterReset={onFilterReset}
                padding={20}
                qaId={'qa-filter-group-chip-map-desktop'}
            />
        </div>
    );
}
