import type { PageComponent } from '@/core/features/a-dynamic-page/dynamic-page-pacts/dynamic-page-type';

import { logger } from '@/core/features/logger/logger';

export const LayoutPositions = [
    'header_top',
    'full',
    'footer',
    'overlay',
    'header',
    'header_left',
    'header_right',
    'aside',
    'main_header',
    'main_header_left',
    'main_header_right',
    'main_content',
    'top_right_absolute',
    'sticky_footer',
] as const;

export function validateLayoutPosition(component: PageComponent) {
    const { layoutPosition } = component.meta;
    if (!LayoutPositions.includes(layoutPosition)) {
        logger.info(`Unknown layoutPosition: ${layoutPosition} for component: ${component['@type']}.`);
    }
}
