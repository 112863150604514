import type { DividerProps } from '@/core/components/divider/divider';

import Divider from '@/core/components/divider/divider';

export default function DividerHorizontalDesktop(props: DividerProps) {
    return (
        <Divider
            marginBottom={15}
            marginTop={20}
            {...props}
        />
    );
}
