import { bemModule } from '@/core/utils/bem-classname';

import styles from './radio-button.module.scss';

const bem = bemModule(styles);

export type RadioButtonProps = {
    hasHoverEffect?: boolean;
    isDisabled?: boolean;
    isSelected: boolean;
};

export default function RadioButton({ hasHoverEffect = false, isDisabled, isSelected }: RadioButtonProps) {
    const isHovered = hasHoverEffect && !isDisabled;
    return (
        <span className={styles.radioButton}>
            <span className={`flex-center ${bem(styles.circle, { isDisabled, isHovered, isSelected })}`}>
                {isSelected && <span className={bem(styles.dot, { isDisabled, isSelected })} />}
            </span>
        </span>
    );
}
