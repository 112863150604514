import React from 'react';

import { LoadingLayer } from '@/core/features';

export default function DesktopContentWrapper(props: React.PropsWithChildren<{ isPageLoading: boolean }>) {
    return (
        <>
            {props.isPageLoading && <LoadingLayer />}
            {props.children}
        </>
    );
}
