import React from 'react';

export default function usePendingState<T>(
    timeout: number,
    dependency?: boolean | null | number | string,
): [T | null, (state: T | null) => void] {
    const timeoutRef = React.useRef<NodeJS.Timeout | null>(null);
    const dependencyRef = React.useRef(dependency);

    const [pendingState, setPendingState] = React.useState<T | null>(null);

    React.useEffect(() => {
        if (pendingState !== null) {
            timeoutRef.current = setTimeout(() => {
                setPendingState(null);
            }, timeout);
        }
        return () => {
            resetTimeout();
        };
    }, [pendingState, timeout]);

    const resetTimeout = () => {
        if (!timeoutRef.current) {
            return;
        }
        clearTimeout(timeoutRef.current);
        timeoutRef.current = null;
    };

    React.useEffect(() => {
        if (dependencyRef.current === dependency) {
            return;
        }

        setPendingState(null);
        resetTimeout();
    }, [dependency]);

    return [pendingState, setPendingState];
}
