import type { IconWrapperProps } from '@/core/components/icon/icon-wrapper';
import type { SVGIconProps } from '@/core/components/icon/svg-icon';

import { IconWrapper } from '@/core/components/icon/icon-wrapper';
import SVGIcon from '@/core/components/icon/svg-icon';

export default function PlusIcon(props: SVGIconProps & IconWrapperProps) {
    return (
        <IconWrapper {...props}>
            <SVGIcon
                {...props}
                height={14}
                viewBox={'0 0 24 24'}
                width={14}
            >
                <g>
                    <defs> </defs>
                    <rect
                        display={'none'}
                        fill={'#FFA400'}
                        height={'24'}
                        vectorEffect={'non-scaling-stroke'}
                        width={'24'}
                    />
                    <rect
                        display={'none'}
                        fill={'none'}
                        height={'24'}
                        id={'_x3C_Slice_x3E__126_'}
                        vectorEffect={'non-scaling-stroke'}
                        width={'24'}
                    />
                    <polygon
                        clipRule={'evenodd'}
                        fillRule={'evenodd'}
                        points={'14,10 14,0 10,0 10,10 0,10 0,14 10,14 10,24 14,24 14,14 24,14 24,10 '}
                        vectorEffect={'non-scaling-stroke'}
                    />
                </g>
            </SVGIcon>
        </IconWrapper>
    );
}

export { PlusIcon };
