import React from 'react';

import useContentScrollRestoration from '@/core/features/scroll/content-scroll-restoration/use-content-scroll-restoration';

export default function useMapFilterScrollRestoration() {
    const filterContainerRef = React.useRef<HTMLDivElement>(null);
    const scrollTopRef = React.useRef(0);

    const onScroll = (top: number) => {
        scrollTopRef.current = top;
    };

    useContentScrollRestoration({
        onScroll,
        ref: filterContainerRef,
        scrollTop: scrollTopRef.current,
    });

    const onFilterReset = () => {
        scrollTopRef.current = 0;
    };

    return { filterContainerRef, onFilterReset };
}
