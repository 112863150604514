/* eslint-disable no-inline-styles/no-inline-styles */
import { MinusIcon } from '@/assets/icons/minus';
import { PlusIcon } from '@/assets/icons/plus';
import { Divider } from '@/core/features';
import color from '@/core/features/styles/color';

export type CustomMapZoomControlEventType = 'zoom-in' | 'zoom-out';

type MapZoomProps = { onZoomControlChange: (eventType: CustomMapZoomControlEventType) => void };

export default function MapZoomControl({ onZoomControlChange }: MapZoomProps) {
    return (
        <div className={'flex-column border-radius-5 overflow-hidden bg-white'}>
            <button
                className={'width-100 padding-15 pointer'}
                onClick={() => onZoomControlChange('zoom-in')}
            >
                <PlusIcon color={color('mineShaft')} />
            </button>
            <Divider
                marginLeft={9}
                marginRight={9}
            />
            <button
                className={'width-100 padding-15 pointer'}
                onClick={() => onZoomControlChange('zoom-out')}
            >
                <MinusIcon color={color('mineShaft')} />
            </button>
        </div>
    );
}
