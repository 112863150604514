import type { FilterGroupV1Option, FilterGroupV1Type } from '@/features/filter/filter-data-v1-type';

import CheckBox from '@/core/components/check-box/check-box';
import RadioButton from '@/core/components/radio-button/radio-button';
import { bemModule } from '@/core/utils/bem-classname';
import useFilterChip from '@/features/filter/filter-chip/use-filter-chip';

import styles from './filter-chip-desktop.module.scss';

const bem = bemModule(styles);

type FilterChipDesktopProps = {
    groupType: FilterGroupV1Type;
    isLoading: boolean;
    onClick: (option: FilterGroupV1Option) => void;
    option: FilterGroupV1Option;
    qaId?: string;
    showDefaultSelected: boolean;
};

export default function FilterChipDesktop({
    groupType,
    isLoading,
    onClick: _onClick,
    option,
    qaId,
    showDefaultSelected,
}: FilterChipDesktopProps) {
    const { count, isActive, onClick, title } = useFilterChip(
        option,
        groupType,
        showDefaultSelected,
        isLoading,
        _onClick,
    );

    const handleClick = () => {
        if (option.count === 0) {
            return;
        }
        onClick();
    };
    return (
        <div
            className={`flex-space-between-horizontal ${bem(styles.filterGroupChipDesktop, {
                isActive,
                isDisabled: option.count === 0,
                isLoading,
            })}`}
            data-qa-id={qaId}
            onClick={handleClick}
        >
            <div className={'flex-space-between-horizontal gap-10  width-100'}>
                <div className={'flex-center gap-10'}>
                    {groupType === 'multiple' ? (
                        <CheckBox
                            hasHoverEffect={true}
                            isDisabled={option.count === 0}
                            isSelected={option.count === 0 ? false : isActive}
                            onClick={handleClick}
                        />
                    ) : (
                        <RadioButton
                            hasHoverEffect={true}
                            isDisabled={option.count === 0}
                            isSelected={option.count === 0 ? false : isActive}
                        />
                    )}
                    <span className={`text-left flex-1 line-clamp-2 ${styles.title}`}>{title}</span>
                </div>
                {count && <span className={'font-size-12 text-nowrap'}>{`${count}`}</span>}
            </div>
        </div>
    );
}
