import type { DynamicPageProps } from '@/core/features/a-dynamic-page/dynamic-page-types';

import React from 'react';

import debounce from 'lodash.debounce';

import ChevronRightSmallIcon from '@/assets/icons/chevron-right-small';
import NotificationWrapper from '@/core/components/notification/notification-wrapper';
import { ButtonWrapper, LoopedLoadingBar } from '@/core/features';
import { getComponentId } from '@/core/features/a-component/services/component-id-service';
import { useClientUrl } from '@/core/features/app/app-atoms';
import useClientOnlyRender from '@/core/features/render/use-client-only-render';
import {
    restorePageScrollPosition,
    savePageScrollPosition,
} from '@/core/features/scroll/page-scroll-restoration/page-scroll-position-service';
import { scrollToTop } from '@/core/features/scroll/scroll-service';
import { atom, useAtom } from '@/core/features/store/atom-store';
import color from '@/core/features/styles/color';
import { bemModule } from '@/core/utils/bem-classname';
import PageComponentSwitchWrapperDesktop from '@/features/a-dynamic-page/components/page-component-switch-wrapper-desktop';
import { useGoogleMapInstance } from '@/features/map/google-map/google-map-state';
import MapComponentDesktop from '@/features/map/map-container/map-component-desktop';
import { useMapLoadingState } from '@/features/map/map-container/map-state';
import { useResetPoiIdInClientUrl } from '@/features/map/map-data-v5/use-map-data-v5';
import DesktopContentWrapper from '@/features/page-layouts/desktop/desktop-content-wrapper';

import styles from './desktop-map-search-layout.module.scss';

const bem = bemModule(styles);

export const showMainContentAtom = atom(true);

export default function DesktopMapSearchLayout(props: DynamicPageProps) {
    const [showMainContent, setShowMainContent] = useAtom(showMainContentAtom);

    const shouldRenderMap = useClientOnlyRender();
    const isMapLoading = useMapLoadingState();
    const [clientUrl] = useClientUrl();

    const onMapResize = () => {
        if (showMainContent) {
            savePageScrollPosition(clientUrl);
        }
        if (!showMainContent) {
            restorePageScrollPosition(clientUrl);
        }
        scrollToTop('instant');

        setShowMainContent(!showMainContent);
    };

    const googleMapInstace = useGoogleMapInstance();
    const resetPoiIdInClientUrl = useResetPoiIdInClientUrl();

    const onMapInteraction = React.useCallback(() => {
        resetPoiIdInClientUrl();

        clearScollToTopTimeout();
        scrollToTopTimeoutRef.current = setTimeout(() => {
            scrollToTop();
        }, 1000);
    }, [resetPoiIdInClientUrl]);

    /**
     * Effect: cleanup scroll to top timeout
     */
    const clearScollToTopTimeout = () => {
        if (scrollToTopTimeoutRef.current !== null) {
            clearTimeout(scrollToTopTimeoutRef.current);
        }
    };

    React.useEffect(() => {
        const scrollHandler = debounce(
            () => {
                clearScollToTopTimeout();
            },
            200,
            { leading: true },
        );
        window.addEventListener('scroll', scrollHandler);

        return () => {
            window.removeEventListener('scroll', scrollHandler);
            clearScollToTopTimeout();
        };
    }, []);

    const scrollToTopTimeoutRef = React.useRef<NodeJS.Timeout | null>(null);

    React.useEffect(() => {
        if (!googleMapInstace) {
            return;
        }
        const zoomChangeListener = googleMapInstace.addListener('zoom_changed', onMapInteraction);
        const dragendListener = googleMapInstace.addListener('dragend', onMapInteraction);
        return () => {
            google.maps.event.removeListener(zoomChangeListener);
            google.maps.event.removeListener(dragendListener);
        };
    }, [googleMapInstace, onMapInteraction]);

    const components = props.components;
    const headerComponents = components.filter((components) => components.meta.layoutPosition === 'header');
    const headerLeftComponents = components.filter((components) => components.meta.layoutPosition === 'header_left');
    const headerRightComponents = components.filter((component) => component.meta.layoutPosition === 'header_right');
    const asideComponents = components.filter((component) => component.meta.layoutPosition === 'aside');
    const mainContentComponents = components.filter((component) => component.meta.layoutPosition === 'main_content');
    const mapDataComponent = components.find((component) => component['@type'] === 'MapDataV5');

    return (
        <>
            <DesktopContentWrapper isPageLoading={props.isPageLoading}>
                <div className={'flex'}>
                    <div className={`${styles.mainLeft} flex-column gap-15 flex-1 width-100`}>
                        {headerComponents.length > 0 && (
                            <div className={bem(styles.header, { isSpotResultsHidden: !showMainContent })}>
                                <div className={'flex-column gap-10 width-100'}>
                                    <div className={'margin-bottom-10'}>
                                        <PageComponentSwitchWrapperDesktop
                                            {...props}
                                            components={headerComponents}
                                        />
                                    </div>
                                    <div className={styles.leftRightContainer}>
                                        {headerLeftComponents.length > 0 && (
                                            <PageComponentSwitchWrapperDesktop
                                                {...props}
                                                components={headerLeftComponents}
                                            />
                                        )}
                                        {headerRightComponents.length > 0 && (
                                            <PageComponentSwitchWrapperDesktop
                                                {...props}
                                                components={headerRightComponents}
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                        )}
                        <div className={'flex gap-15'}>
                            {asideComponents.length > 0 && (
                                <div
                                    className={`${bem(styles.mainContentAside, { isSpotResultsHidden: !showMainContent })} flex-column gap-10`}
                                >
                                    <PageComponentSwitchWrapperDesktop
                                        {...props}
                                        components={asideComponents}
                                    />
                                </div>
                            )}
                            {mainContentComponents.length > 0 && (
                                <div
                                    className={bem(styles.mainContentSpotResult, {
                                        isSpotResultsHidden: !showMainContent,
                                    })}
                                >
                                    <div className={'flex-column gap-15'}>
                                        <PageComponentSwitchWrapperDesktop
                                            {...props}
                                            components={mainContentComponents}
                                        />
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    <div
                        className={`${bem(styles.mainRight, { isSpotResultsHidden: !showMainContent })} flex-center height-100dvh`}
                    >
                        <ButtonWrapper
                            className={`${styles.toogleButton} flex-center border-radius pointer`}
                            height={40}
                            onClick={onMapResize}
                            width={40}
                        >
                            <span
                                className={'flex-center bg-wildSand'}
                                data-qa-id={'qa-map-split-toggle-button'}
                            >
                                <ChevronRightSmallIcon
                                    color={color('black-09')}
                                    height={15}
                                    rotate={showMainContent ? 180 : 0}
                                    width={15}
                                />
                            </span>
                        </ButtonWrapper>
                        <div className={'width-100'}>
                            {mapDataComponent && shouldRenderMap && (
                                <div className={'bg-wildSand height-100dvh height-100 overflow-hidden'}>
                                    {isMapLoading && (
                                        <LoopedLoadingBar className={'absolute top-0 left-10 width-100'} />
                                    )}
                                    <MapComponentDesktop componentId={getComponentId(mapDataComponent['@id'])} />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </DesktopContentWrapper>
            <NotificationWrapper />
        </>
    );
}
