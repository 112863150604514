import { CheckMarkIcon } from '@/assets/icons/check-mark';
import color from '@/core/features/styles/color';
import { bemModule } from '@/core/utils/bem-classname';

import styles from './check-box.module.scss';

const bem = bemModule(styles);

type CheckBoxBorderProps = {
    className?: string;
    hasHoverEffect?: boolean;
    isDisabled?: boolean;
    isSelected: boolean;
    onClick?: (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void;
};

export default function CheckBox({
    className,
    hasHoverEffect = false,
    isDisabled,
    isSelected,
    onClick,
}: CheckBoxBorderProps) {
    const isHovered = hasHoverEffect && !isDisabled;
    return (
        <span
            className={`flex-center ${bem(styles.checkbox, { isDisabled, isHovered, isSelected })} ${className ?? ''}`}
            onClick={onClick}
        >
            {isSelected && (
                <CheckMarkIcon
                    color={color('white')}
                    height={10}
                    width={14}
                />
            )}
        </span>
    );
}
